import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader="Don't hesitate to write us a message. We're open to cooperation, feedback and any type of requests. 
          We’ll get back to you within 48 hours with response."
        />
        <h2>Mission</h2>
        <text>Source of valuable resources like articles, scripts and services improving privacy among the Internet and rising the awarenss of rights.</text>
        <br></br>
        <h2>Vision</h2>
        <text></text>
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
